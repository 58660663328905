.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.map {
  height: 100vh;
  width: 100%;
}

.timebox {
  height: 60px;
  width: 300px;
  position: absolute;
  bottom: 30px;
  right: 0;
  left: 0;
  margin: auto;
  padding: 15px 15px;
  background-color: hsla(0,0%,0%,70%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.timebox > span {
  color: white;
  font-weight: 600;
}

/* Help screen */

.help-screen {
  font-size: 16px;
}

.help-screen > img {
  margin-top: 35px;
  max-width: 300px;
  width: 100%;
}

.help-screen > h2 {
  font-size: 24px;
}
